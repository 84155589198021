import React from 'react'
import { notification, Icon } from 'antd'

import SieYearService from './SieYearService'
import moment from 'moment'
const SieMonthService = {
  getYearsFromSies: (sieFile0, sieFile1, sieFile2) => {
    const previousYear = (date) =>
      moment(date)
        .subtract(1, 'd')
        .format('YYYY')
    const res = [
      ...new Set([
        // Föregående år från nuvarande SIE.
        previousYear(sieFile0.sie.list('RAR', 'årsnr', '0')[0].start),
        // Detta år från nuvarande SIE
        sieFile0.sie.list('RAR', 'årsnr', '0')[0].slut.slice(0, 4),
        // Föregående år från förra SIE
        sieFile1
          ? previousYear(sieFile1.sie.list('RAR', 'årsnr', '0')[0].start)
          : null,
        // Detta år från förra SIE
        sieFile1
          ? sieFile1.sie.list('RAR', 'årsnr', '0')[0].slut.slice(0, 4)
          : null,
        // Föregående år för sista SIE
        sieFile2
          ? previousYear(sieFile2.sie.list('RAR', 'årsnr', '0')[0].start)
          : null,
        // Detta år för sista SIE
        sieFile2
          ? sieFile2.sie.list('RAR', 'årsnr', '0')[0].slut.slice(0, 4)
          : null,
      ]),
    ].filter((year) => !!year)

    return res
  },
  getMonthsFromSies: (sieFile0, sieFile1, sieFile2) => {
    const months = (year) => {
      var N = 12
      return Array.apply(null, { length: N })
        .map(Number.call, Number)
        .map((n) => year.toString(10) + (n + 1 > 9 ? n + 1 : '0' + (n + 1)))
        .reverse()
    }
    const years = SieMonthService.getYearsFromSies(sieFile0, sieFile1, sieFile2)

    return years
      .sort()
      .reverse()
      .map((year) => months(year))
      .flat()
  },
  accResForAccountMonth: (monthAccountSums, account, year, month) => {
    const monthAccountSum = monthAccountSums[`${year}${month}-${account}`]
    if (!monthAccountSum) {
      const accountMonthSums = Object.keys(monthAccountSums).filter(
        (monthAccount) => monthAccount.slice(7, 11) === account
      )
      const lastAccountMonthSum = accountMonthSums.sort()[
        accountMonthSums.length - 1
      ]
      return lastAccountMonthSum
        ? monthAccountSums[lastAccountMonthSum].accRes
        : 0
    }
    return monthAccountSum ? monthAccountSum.accRes : 0
  },
  monthAccountSumsColumnized: (columns, account, monthAccountSums) => {
    return columns.map((yeardate) =>
      monthAccountSums[yeardate + '-' + account]
        ? parseInt(account) < 3000
          ? monthAccountSums[yeardate + '-' + account]['accRes']
          : monthAccountSums[yeardate + '-' + account]['res']
        : '0'
    )
  },
  getMonthAccountSums: (sieFile0, sieFile1, sieFile2) => {
    const allVers = SieYearService.getAllVers(sieFile0, sieFile1, sieFile2)

    let allTransactions = allVers.map((ver) => {
      try {
        return ver.poster
          .filter((post) => post.etikett === 'trans')
          .map((post) => [
            post.kontonr,
            ver.verdatum || post.transdat,
            Math.round(parseFloat(post.belopp) * 100),
          ])
      } catch (e) {
        notification.open({
          message: 'A SIE issue occured',
          duration: 0,
          description: `There was a problem at line ${ver.lineNumber}. Line was skipped, but check your SIE to be sure it's valid.`,
          icon: <Icon type="warning" style={{ color: 'red' }} />,
        })
        console.error(e)
        return null
      }
    })
    const flatTransactions = allTransactions.flat().filter((x) => x)

    let groupedTransactions = {}
    flatTransactions.forEach((transaction) => {
      const key = `${transaction[1].slice(0, 6)}-${transaction[0]}`
      let foundGroup = groupedTransactions[key]
      if (!foundGroup) {
        foundGroup = groupedTransactions[key] = []
      }
      foundGroup.push(transaction)
    })

    let accountMonthSums = {}
    Object.keys(groupedTransactions).forEach((key) => {
      accountMonthSums[key] = groupedTransactions[key].reduce(
        (accumulator, current) => accumulator + parseInt(current[2]),
        0
      )
    })
    return accountMonthSums
  },
  getMonthAccountSumsWithBalanceAcc: (
    allAccounts,
    sieFile0,
    sieFile1,
    sieFile2
  ) => {
    const accountMonthSums = SieMonthService.getMonthAccountSums(
      sieFile0,
      sieFile1,
      sieFile2
    )
    // Create an array of sie files with start and date for filtering
    const addSieFile = (sieFilesWithStartEnd, file) => {
      if (file) {
        return sieFilesWithStartEnd.concat({
          sieFile: file,
          start: parseInt(
            file.sie.list('RAR', 'årsnr', '0')[0].start.slice(0, 6)
          ),
          end: parseInt(file.sie.list('RAR', 'årsnr', '0')[0].slut.slice(0, 6)),
        })
      }
      return sieFilesWithStartEnd
    }

    let sieFilesWithStartEnd = []
    sieFilesWithStartEnd = addSieFile(sieFilesWithStartEnd, sieFile0)
    sieFilesWithStartEnd = addSieFile(sieFilesWithStartEnd, sieFile1)
    sieFilesWithStartEnd = addSieFile(sieFilesWithStartEnd, sieFile2)
    // Get the correct sie to look in for this yearmonth
    const findSieForYearMonth = (sieFilesWithStartEnd, yearmonth) =>
      sieFilesWithStartEnd.find(
        (sieFileWithStartEnd) =>
          sieFileWithStartEnd.start <= parseInt(yearmonth) &&
          sieFileWithStartEnd.end >= parseInt(yearmonth)
      )
    // Get the sum for all month up until this for the account
    const periodBalanceToDateForAccount = (
      sieFileForYearMonth,
      yearMonthAccounts,
      toYearMonth,
      forAccount
    ) => {
      const toYearMonthInt = parseInt(toYearMonth)

      // Slow
      const filtered = yearMonthAccounts.filter((yearmonthAccount) => {
        if (yearmonthAccount.indexOf(forAccount) !== 7) {
          return false
        }
        const yearmonth = parseInt(yearmonthAccount.slice(0, 6))

        return (
          yearmonth <= toYearMonthInt && yearmonth >= sieFileForYearMonth.start
        )
      })

      const res = filtered.reduce(
        (accumulator, key) => accumulator + accountMonthSums[key],
        0
      )

      return res
    }
    // All months without verifications on balance account we add the prev months balance
    const monthColumns = SieMonthService.getMonthsFromSies(
      sieFile0,
      sieFile1,
      sieFile2
    )

    const allAccountsToJustify = [
      ...new Set(
        Object.values(allAccounts)
          .filter(({ kontonr, res0, res1, res2 }) => res1 || res2 || res0)
          .map(({ kontonr }) => kontonr)
          .concat(Object.keys(accountMonthSums).map((key) => key.slice(7, 11)))
      ),
    ]
    const yearMonthBalanceAccountToJustify = []
    const lastVerificationMonth = SieYearService.lastVerification(
      sieFile0,
      sieFile1,
      sieFile2
    ).slice(0, 6)
    monthColumns.forEach((monthColumn) => {
      if (parseInt(lastVerificationMonth) < parseInt(monthColumn)) {
        return
      }
      allAccountsToJustify.forEach((account) =>
        yearMonthBalanceAccountToJustify.push(monthColumn + '-' + account)
      )
    })
    // Here we map up new array with balances
    const lastSie = [sieFile0, sieFile1, sieFile2]
      .filter((sieFile) => sieFile && sieFile.sie)
      .slice(-1)[0]

    const yearMonthForResBeforeSie = moment(
      lastSie.sie.list('RAR', 'årsnr', '0')[0].start
    )
      .subtract(1, 'd')
      .format('YYYYMM')

    // ------------
    const accountMonthSumsWithBalances = []
    ;[
      ...new Set(
        Object.keys(accountMonthSums).concat(yearMonthBalanceAccountToJustify)
      ),
    ].map((yearmonthAccount) => {
      const yearmonth = yearmonthAccount.slice(0, 6)
      const forAccount = yearmonthAccount.slice(7, 11)

      const sieFileForYearMonth = findSieForYearMonth(
        sieFilesWithStartEnd,
        yearmonth
      )

      if (yearmonth === yearMonthForResBeforeSie) {
        const res = lastSie.sie.list(
          forAccount < 3000 ? 'ub' : 'res',
          'konto',
          forAccount
        )[1]

        return (accountMonthSumsWithBalances[yearmonthAccount] = {
          accRes: res ? Math.round(parseFloat(res.saldo) * 100) : 0,
          res: res ? Math.round(parseFloat(res.saldo) * 100) : 0,
        })
      }

      if (!sieFileForYearMonth) {
        return (accountMonthSumsWithBalances[yearmonthAccount] = {
          accRes: 0,
          res: 0,
        })
      }

      // Get Ingående Balans for that account from this SIE
      const ibAccount = sieFileForYearMonth.sieFile.sie
        .list('ib', 'årsnr', '0')
        .find(({ konto }) => konto === forAccount)

      const ibForAccountYearMonth = parseInt(
        ibAccount ? Math.round(parseFloat(ibAccount.saldo) * 100) : 0
      )
      // console.dir(accountMonthSums)
      const yearMonthAccounts = Object.keys(accountMonthSums)
      const res = (accountMonthSumsWithBalances[yearmonthAccount] = {
        accRes:
          ibForAccountYearMonth +
          periodBalanceToDateForAccount(
            sieFileForYearMonth,
            yearMonthAccounts,
            yearmonth,
            forAccount
          ),
        res: accountMonthSums[yearmonthAccount] || 0,
      })

      return res
    })

    return accountMonthSumsWithBalances
  },
}

export default SieMonthService
