import React, { Component, Fragment } from 'react'
import iconv from 'iconv-lite'
import Parser from './sie-parser'
import './App.css'
import Dropzone from 'react-dropzone'
import { Spin } from 'antd'
var fileReaderStream = require('filereader-stream')
iconv.enableStreamingAPI(require('stream'))

export default class UploadSie extends Component {
  state = {
    loading: false,
  }
  handleFiles = (files) => {
    var converterStream = iconv.decodeStream('CP437')
    var readStream = fileReaderStream(files[0])
    readStream.pipe(converterStream)
    const parser = new Parser()
    converterStream.on('data', function(str) {
      parser.parseLine(str)
    })
    converterStream.on('finish', () => {
      this.props.onSieChange({
        file: files[0],
        sie: parser.sieFile(),
      })
      this.setState({ loading: false })
    })
  }
  render() {
    return (
      <Dropzone
        onDrop={(acceptedFiles) =>
          this.setState({ loading: true }, this.handleFiles(acceptedFiles))
        }
      >
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              border: '3px dashed #eee',
              padding: '20px',
            }}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p style={{ margin: '0' }}>
                Dra och släpp eller klicka för att ladda upp
              </p>
            </div>
            {this.state.loading && (
              <Fragment>
                <br />
                Uploading... <Spin />
              </Fragment>
            )}
          </section>
        )}
      </Dropzone>
    )
  }
}
