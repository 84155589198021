import React, { Component } from 'react'
import { Button, message } from 'antd'
import SieYearService from './SieYearService'
import SieMonthService from './SieMonthService'
import SpreadsheetService from './SpreadsheetService'

export default class CopyButton extends Component {
  state = {
    loading: false,
  }
  doCopy = () => {
    this.setState({ loading: true })
    setTimeout(() => {
      var str = SpreadsheetService.buildSpreadsheetableString(
        this.buildData()
      ).replace(/\./g, ',')
      navigator.clipboard.writeText(str).then(
        () => {
          this.setState({ loading: false })
          message.success(
            'Kopierade datan. Nu kan du klistra in det i ditt doc!'
          )
        },
        () => {
          this.setState({ loading: false })
          message.success(
            'Gick inte att kopiera. Prova med en annan webbläsare!'
          )
        }
      )
    }, 20)
  }
  buildColumns = () => {
    const { sieFile0, sieFile1, sieFile2 } = this.props
    const years = SieMonthService.getYearsFromSies(sieFile0, sieFile1, sieFile2)
      .sort()
      .reverse()
      .filter(
        (year) =>
          parseInt(`${year}${this.props.endMonth}`) <=
          parseInt(sieFile0.sie.list('RAR', 'årsnr', '0')[0].slut.slice(0, 6))
      )
    return [
      'KtoNr',
      'KtoNamn',
      years[0] ? `${years[0]}${this.props.endMonth}` : '',
      years[1] ? `${years[1]}${this.props.endMonth}` : '',
      years[2] ? `${years[2]}${this.props.endMonth}` : '',
      years[3] ? `${years[3]}${this.props.endMonth}` : '',
      '',
    ].concat(SieMonthService.getMonthsFromSies(sieFile0, sieFile1, sieFile2))
  }
  buildData = () => {
    const { sieFile0, sieFile1, sieFile2 } = this.props
    let yearMatrix = [this.buildColumns()]
    const accounts = SieYearService.getAccounts(sieFile0, sieFile1, sieFile2)
    const monthAccountSums = SieMonthService.getMonthAccountSumsWithBalanceAcc(
      accounts,
      sieFile0,
      sieFile1,
      sieFile2
    )
    const monthColumns = SieMonthService.getMonthsFromSies(
      sieFile0,
      sieFile1,
      sieFile2
    )
    const years = SieMonthService.getYearsFromSies(sieFile0, sieFile1, sieFile2)
      .sort()
      .reverse()
      .filter(
        (year) =>
          parseInt(`${year}${this.props.endMonth}`) <=
          parseInt(sieFile0.sie.list('RAR', 'årsnr', '0')[0].slut.slice(0, 6))
      )
    yearMatrix = yearMatrix.concat(
      Object.values(accounts)
        // Skip every row with just zeros
        .filter(
          (account) =>
            !(
              !account.res0 &&
              !account.res1 &&
              !account.res2 &&
              !account.res3 &&
              SieMonthService.monthAccountSumsColumnized(
                monthColumns,
                account.kontonr,
                monthAccountSums
              ).every((monthValue) => !(monthValue > 0))
            )
        )
        .map((account) =>
          [
            account.kontonr,
            account.kontonamn,
            SieMonthService.accResForAccountMonth(
              monthAccountSums,
              account.kontonr,
              years[0],
              this.props.endMonth
            ),
            SieMonthService.accResForAccountMonth(
              monthAccountSums,
              account.kontonr,
              years[1],
              this.props.endMonth
            ),
            years[2]
              ? SieMonthService.accResForAccountMonth(
                  monthAccountSums,
                  account.kontonr,
                  years[2],
                  this.props.endMonth
                )
              : '0',
            years[3]
              ? SieMonthService.accResForAccountMonth(
                  monthAccountSums,
                  account.kontonr,
                  years[3],
                  this.props.endMonth
                )
              : '0',
            '',
          ].concat(
            SieMonthService.monthAccountSumsColumnized(
              monthColumns,
              account.kontonr,
              monthAccountSums
            )
          )
        )
    )

    return yearMatrix
  }
  render() {
    const { sieFile0 } = this.props
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          shape="round"
          icon="copy"
          size="large"
          style={{ margin: '30px auto' }}
          loading={this.state.loading}
          disabled={!sieFile0}
          onClick={this.doCopy}
        >
          Kopiera till Google Spreadsheet
        </Button>
      </div>
    )
  }
}
