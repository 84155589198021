import React, { Component } from 'react'
import { Card, List, Tag } from 'antd'
import UploadSie from './UploadSie'

export default class SieCard extends Component {
  render() {
    const { sieFile, onSieChange, title, fileIndex } = this.props
    return (
      <Card
        type="inner"
        size="small"
        title={title}
        style={{ margin: '0 10px', overflow: 'hidden' }}
      >
        <UploadSie onSieChange={onSieChange} />
        {sieFile && (
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={[
              <React.Fragment>
                <strong style={{ marginRight: '3px' }}>
                  {sieFile.sie.list('FNAMN')[0].företagsnamn}
                </strong>{' '}
                {sieFile.sie.list('ORGNR')[0] &&
                  `(${sieFile.sie.list('ORGNR')[0].orgnr})`}
              </React.Fragment>,
              `${sieFile.sie.list('RAR', 'årsnr', '0')[0].start} - ${
                sieFile.sie.list('RAR', 'årsnr', '0')[0].slut
              }`,
              `Sista ver ${
                sieFile.sie
                  .list('ver')
                  .map(({ verdatum }) => verdatum)
                  .sort()
                  .reverse()[0]
              }`,
              sieFile.sie
                .list('res', 'konto', '8999')
                .find((account) => account['års'] === '0') ? (
                <Tag color="green">Resultat bokat</Tag>
              ) : (
                <Tag color="red">Resultat ej bokat</Tag>
              ),
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        )}
      </Card>
    )
  }
}
