import React, { Component } from 'react'
import { Layout, Row, Col, Select } from 'antd'
import logo from './logo.svg'
import SieCard from './SieCard'
import CopyButton from './CopyButton'
import './App.css'

export default class App extends Component {
  state = { sieFiles0: null, endMonth: undefined }
  render() {
    return (
      <div>
        <Layout
          style={{
            margin: '20px auto',
            width: '800px',
            background: 'white',
            border: '1px solid #e8e8e8',
          }}
        >
          <div
            style={{
              height: '600px',
            }}
          >
            <Layout.Header
              style={{
                margin: '0px',
                background: 'white',
                borderBottom: '1px solid #e8e8e8',
                padding: '0 20px',
              }}
            >
              <h1>SIE-läsare</h1>
            </Layout.Header>
            <Layout.Content style={{ paddingTop: '20px' }}>
              <Row type="flex">
                <Col span={8}>
                  <SieCard
                    sieFile={this.state.sieFile0}
                    title="Årets"
                    onSieChange={(sieFile) => {
                      this.setState({
                        sieFile0: sieFile,
                        endMonth: sieFile.sie
                          .list('RAR', 'årsnr', '0')[0]
                          .slut.slice(4, 6),
                      })
                    }}
                  />
                </Col>
                <Col span={8}>
                  <SieCard
                    sieFile={this.state.sieFile1}
                    title="År -1"
                    onSieChange={(sieFile) => {
                      this.setState({ sieFile1: sieFile })
                    }}
                  />
                </Col>
                <Col span={8}>
                  <SieCard
                    sieFile={this.state.sieFile2}
                    title="År -2"
                    onSieChange={(sieFile) => {
                      this.setState({ sieFile2: sieFile })
                    }}
                  />
                </Col>
              </Row>
              <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <span>Summera årsresultat fram t.o.m</span>
                <br />
                <Select
                  showSearch
                  style={{ width: 320, marginTop: '10px' }}
                  placeholder="Välj månad"
                  optionFilterProp="children"
                  onChange={(value) => this.setState({ endMonth: value })}
                  size="large"
                  value={this.state.endMonth}
                  disabled={!this.state.sieFile0}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.sieFile0 &&
                    [
                      'Januari',
                      'Februari',
                      'Mars',
                      'April',
                      'Maj',
                      'Juni',
                      'Juli',
                      'Augusti',
                      'September',
                      'Oktober',
                      'November',
                      'December',
                    ].map((month, index) => (
                      <Select.Option
                        value={index < 9 ? `0${index + 1}` : index + 1}
                      >
                        {month}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <CopyButton
                endMonth={this.state.endMonth}
                sieFile0={this.state.sieFile0}
                sieFile1={this.state.sieFile1}
                sieFile2={this.state.sieFile2}
              />
            </Layout.Content>
          </div>
        </Layout>
        <img
          src={logo}
          className="logo"
          alt="logo"
          style={{ height: '20px' }}
        />
      </div>
    )
  }
}
