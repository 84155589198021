import React from 'react'
import { notification, Icon } from 'antd'

const SieYearService = {
  lastVerification: (sieFile0, sieFile1, sieFile2) => {
    const allVers = SieYearService.getAllVers(sieFile0, sieFile1, sieFile2)
    return allVers
      .filter((ver) => ver.vernr)
      .map(({ verdatum }) => verdatum)
      .sort()
      .reverse()[0]
  },
  getAllVers: (sieFile0, sieFile1, sieFile2) => {
    let allVers = sieFile0.sie.list('ver')
    if (sieFile1) {
      allVers = allVers.concat(sieFile1.sie.list('ver'))
    }
    if (sieFile2) {
      allVers = allVers.concat(sieFile2.sie.list('ver'))
    }
    return allVers
  },
  getAllAccounts: (sieFile0, sieFile1, sieFile2) => {
    let accounts = {}
    const addKontonFromSie = (sieFile) => {
      if (!sieFile) {
        return
      }
      sieFile.sie.list('konto').forEach(({ kontonr, kontonamn }) => {
        if (!accounts[kontonr]) {
          accounts[kontonr] = {
            kontonr: kontonr,
            kontonamn: kontonamn,
          }
        }
      })
    }
    addKontonFromSie(sieFile0)
    addKontonFromSie(sieFile1)
    addKontonFromSie(sieFile2)
    return accounts
  },
  getAccounts: (sieFile0, sieFile1, sieFile2) => {
    let accounts = SieYearService.getAllAccounts(sieFile0, sieFile1, sieFile2)

    const populateAccount = (sieFile, post, savePoint) => {
      if (/^\d{4,6}$/.test(post.konto)) {
        if (accounts[post.konto]) {
          accounts[post.konto][savePoint] = Math.round(
            parseFloat(post.saldo) * 100
          )
        } else {
          notification.open({
            message: 'A SIE issue occured',
            duration: 0,
            description: `There was a problem at line ${post.lineNumber}. Seems like an account number not in the kontoplan of any SIE "${post.konto}"`,
            icon: <Icon type="warning" style={{ color: 'red' }} />,
          })
        }
      } else {
        notification.open({
          message: 'A SIE issue occured',
          duration: 0,
          description: `There was a problem at line ${post.lineNumber}. Seems like an invalid account number "${post.konto}"`,
          icon: <Icon type="warning" style={{ color: 'red' }} />,
        })
      }
    }

    sieFile0.sie
      .list('ub', 'årsnr', '0')
      .forEach((post) => populateAccount(sieFile0, post, 'res0'))

    if (sieFile1) {
      sieFile1.sie
        .list('ub', 'årsnr', '0')
        .forEach((post) => populateAccount(sieFile1, post, 'res1'))
    } else {
      sieFile0.sie
        .list('ub', 'årsnr', '-1')
        .forEach((post) => populateAccount(sieFile0, post, 'res1'))
    }

    if (sieFile2) {
      sieFile2.sie
        .list('ub', 'årsnr', '0')
        .forEach((post) => populateAccount(sieFile2, post, 'res2'))
    } else if (sieFile1) {
      sieFile1.sie
        .list('ub', 'årsnr', '-1')
        .forEach((post) => populateAccount(sieFile1, post, 'res2'))
    }

    if (sieFile2) {
      sieFile2.sie
        .list('ub', 'årsnr', '-1')
        .forEach((post) => populateAccount(sieFile2, post, 'res3'))
    }

    sieFile0.sie
      .list('res', 'års', '0')
      .forEach((post) => populateAccount(sieFile0, post, 'res0'))

    if (sieFile1) {
      sieFile1.sie
        .list('res', 'års', '0')
        .forEach((post) => populateAccount(sieFile1, post, 'res1'))
    } else {
      sieFile0.sie
        .list('res', 'års', '-1')
        .forEach((post) => populateAccount(sieFile0, post, 'res1'))
    }

    if (sieFile2) {
      sieFile2.sie
        .list('res', 'års', '0')
        .forEach((post) => populateAccount(sieFile2, post, 'res2'))
    } else if (sieFile1) {
      sieFile1.sie
        .list('res', 'års', '-1')
        .forEach((post) => populateAccount(sieFile1, post, 'res2'))
    }

    if (sieFile2) {
      sieFile2.sie
        .list('res', 'års', '-1')
        .forEach((post) => populateAccount(sieFile2, post, 'res3'))
    }
    return accounts
  },
}

export default SieYearService
